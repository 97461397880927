import axios from 'axios';
import {FetchStatus} from '../../constants/enums';
import {
  ADD_AUTH_ERRORS,
  REMOVE_AUTH_ERRORS,
  SET_FETCH_AUTH_STATUS,
  RECEIVE_AUTH_DATA,
  SET_ADD_FACEBOOK_ACCOUNT_STATUS,
  RECEIVE_FACEBOOK_ACCOUNT_DATA,
  SET_ADD_GOOGLE_ACCOUNT_STATUS,
  RECEIVE_GOOGLE_ACCOUNT_DATA,
  SET_REAUTHORIZE_FACEBOOK_STATUS,
  SET_REAUTHORIZE_GOOGLE_STATUS,
  SET_DELETE_ACCOUNT_STATUS,
  SET_ADD_OUTBRAIN_ACCOUNT_STATUS,
  RECEIVE_OUTBRAIN_ACCOUNT_DATA
} from './constants';

const baseURL = process.env.REACT_APP_BACKEND_URL;

// Errors
export const addAuthErrors = (data) => {
  return {
    type: ADD_AUTH_ERRORS,
    payload: data
  };
};

export const removeAuthErrors = () => {
  return {type: REMOVE_AUTH_ERRORS};
};

export const setFetchAuthStatus = (status) => {
  return {
    type: SET_FETCH_AUTH_STATUS,
    payload: status
  };
};

export const setAddFacebookAccountStatus = (status) => {
  return {
    type: SET_ADD_FACEBOOK_ACCOUNT_STATUS,
    payload: status
  };
};

export const setAddGoogleAccountStatus = (status) => {
  return {
    type: SET_ADD_GOOGLE_ACCOUNT_STATUS,
    payload: status,
  };
};

const setAddOutbrainAccountStatus = (status) => {
  return {
    type: SET_ADD_OUTBRAIN_ACCOUNT_STATUS,
    payload: status,
  };
};

const receiveAuthData = (data) => {
  return {
    type: RECEIVE_AUTH_DATA,
    payload: data
  };
};

const receiveFacebookAccountData = (data) => {
  return {
    type: RECEIVE_FACEBOOK_ACCOUNT_DATA,
    payload: data
  };
};

const receiveGoogleAccountData = (data) => {
  return {
    type: RECEIVE_GOOGLE_ACCOUNT_DATA,
    payload: data
  }
}

const receiveOutbrainAccountData = (data) => {
  return {
    type: RECEIVE_OUTBRAIN_ACCOUNT_DATA,
    payload: data
  }
}

const setReauthorizeFacebookStatus = (data) => {
  return {
    type: SET_REAUTHORIZE_FACEBOOK_STATUS,
    payload: data,
  }
}

const setReauthorizeGoogleStatus = (data) => {
  return {
    type: SET_REAUTHORIZE_GOOGLE_STATUS,
    payload: data,
  }
}

const setDeleteAccountStatus = (data) => {
  return {
    type: SET_DELETE_ACCOUNT_STATUS,
    payload: data,
  }
}

export const fetchAuthData = (token, user) => async (dispatch, getState) => {
  if (getState().auth.fetchAuthStatus !== null) return;

  try {
    dispatch(setFetchAuthStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/login`;

    const payload = {
      name: user.name,
      email: user.email,
      image_url: user.picture,
      nickname: user.name,
      sub: user.sub
    };
    const {data} = await axios.post(url, payload, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(setFetchAuthStatus(FetchStatus.SUCCESS));
    dispatch(receiveAuthData(data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setFetchAuthStatus(FetchStatus.FAILURE));
  }
};

export const addFacebookAccount = (token, user) => async (dispatch) => {
  try {
    dispatch(setAddFacebookAccountStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/add_facebook_account`;
    const {data} = await axios.post(url, user, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(setAddFacebookAccountStatus(FetchStatus.SUCCESS));
    dispatch(receiveFacebookAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setAddFacebookAccountStatus(FetchStatus.FAILURE));
  }
};

export const addGoogleAccount = (token, code) => async (dispatch) => {
  try {
    dispatch(setAddGoogleAccountStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/add_google_account`;
    const { data } = await axios.post(url, { code }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(setAddGoogleAccountStatus(FetchStatus.SUCCESS));
    dispatch(receiveGoogleAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setAddGoogleAccountStatus(FetchStatus.FAILURE));
  }
}

export const addOutbrainAccount = (token, userObj) => async (dispatch) => {
  try {
    dispatch(setAddOutbrainAccountStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/add_outbrain_account`;
    const { data } = await axios.post(url, { userObj }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(setAddOutbrainAccountStatus(FetchStatus.SUCCESS));
    dispatch(receiveOutbrainAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setAddOutbrainAccountStatus(FetchStatus.FAILURE));
  }
}

export const reauthorizeFacebook = (token, accountId, user) => async (dispatch) => {
  try {
    dispatch(setReauthorizeFacebookStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/reauthorize_facebook`;
    const { data } = await axios.post(url, { user, accountId },   {
      headers: {
        Authorization: `Bearer ${token}`
      }
    });
    dispatch(setReauthorizeFacebookStatus(FetchStatus.SUCCESS));
    dispatch(receiveFacebookAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setReauthorizeFacebookStatus(FetchStatus.FAILURE));
  }
}

export const reauthorizeGoogle = (token, accountId, code) => async (dispatch) => {
  try {
    dispatch(setReauthorizeGoogleStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth/reauthorize_google`;
    const { data } = await axios.post(url, { accountId, code }, {
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(setReauthorizeGoogleStatus(FetchStatus.SUCCESS));
    dispatch(receiveGoogleAccountData(data.data));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setReauthorizeGoogleStatus(FetchStatus.FAILURE));
  }
}

export const deleteAccount = (token, accountId) => async (dispatch) => {
  try {
    dispatch(setDeleteAccountStatus(FetchStatus.PENDING));
    const url = `${baseURL}api/auth`;
    await axios.delete(url, {
      data: { accountId },
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    dispatch(setDeleteAccountStatus(FetchStatus.SUCCESS));
  } catch ({response}) {
    dispatch(addAuthErrors(response));
    dispatch(setDeleteAccountStatus(FetchStatus.FAILURE));
  }
}
