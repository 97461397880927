import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {get, isequal} from 'lodash';

const useRedux = (keys, eqFunc = isequal) => {
  const dispatch = useDispatch();
  return useSelector((store) => {
    const keyList = Array.isArray(keys) ? keys : [keys];
    const mapped = [];

    keyList.forEach((key) => {
      const value = key.includes('.') ? get(store, key) : store[key];
      mapped.push(value);
    });

    mapped.push(dispatch);
    return mapped;
  }, eqFunc);
};

// https://usehooks.com/useWindowSize/
const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }
    // Add event listener
    window.addEventListener('resize', handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};

export {useRedux, useWindowSize};
