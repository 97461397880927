import {
  ADD_AUTH_ERRORS,
  REMOVE_AUTH_ERRORS,
  SET_FETCH_AUTH_STATUS,
  RECEIVE_AUTH_DATA,
  SET_ADD_FACEBOOK_ACCOUNT_STATUS,
  RECEIVE_FACEBOOK_ACCOUNT_DATA,
  SET_ADD_GOOGLE_ACCOUNT_STATUS,
  RECEIVE_GOOGLE_ACCOUNT_DATA,
  SET_REAUTHORIZE_FACEBOOK_STATUS,
  SET_REAUTHORIZE_GOOGLE_STATUS,
  SET_DELETE_ACCOUNT_STATUS,
} from './constants';

const initialState = {
  errors: [],
  fetchAuthStatus: null,
  addFacebookAccountStatus: null,
  addGoogleAccountStatus: null,
  reauthorizeFacebookStatus: null,
  reauthorizeGoogleStatus: null,
  authData: {},
  facebookAccountData: {},
  googleAccountData: {}
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_AUTH_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_AUTH_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_AUTH_STATUS: {
      return {
        ...state,
        fetchAuthStatus: action.payload
      };
    }
    case RECEIVE_AUTH_DATA: {
      return {
        ...state,
        authData: action.payload
      };
    }
    case SET_ADD_FACEBOOK_ACCOUNT_STATUS: {
      return {
        ...state,
        addFacebookAccountStatus: action.payload
      };
    }
    case RECEIVE_FACEBOOK_ACCOUNT_DATA: {
      return {
        ...state,
        facebookAccountData: action.payload
      };
    }

    case SET_ADD_GOOGLE_ACCOUNT_STATUS: {
      return {
        ...state,
        addGoogleAccountStatus: action.payload
      };
    }

    case RECEIVE_GOOGLE_ACCOUNT_DATA: {
      return {
        ...state,
        googleAccountData: action.payload
      };
    }

    case SET_REAUTHORIZE_FACEBOOK_STATUS: {
      return {
        ...state,
        reauthorizeFacebookStatus: action.payload
      };
    }

    case SET_REAUTHORIZE_GOOGLE_STATUS: {
      return {
        ...state,
        reauthorizeGoogleStatus: action.payload
      };
    }

    case SET_DELETE_ACCOUNT_STATUS: {
      return {
        ...state,
        deleteAccountStatus: action.payload,
      }
    }

    default:
      return state;
  }
};

export default authReducer;
