import {
  ADD_FACEBOOK_ERRORS,
  REMOVE_FACEBOOK_ERRORS,
  SET_FETCH_FACEBOOK_STATUS,
  RECEIVE_FACEBOOK_DATA,
  RECEIVE_FACEBOOK_IDENTITY
} from './constants';

const initialState = {
  errors: [],
  fetchFacebookStatus: null,
  facebookData: [],
  identity: {}
};

const facebookReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_FACEBOOK_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_FACEBOOK_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_FACEBOOK_STATUS: {
      return {
        ...state,
        fetchFacebookStatus: action.payload
      };
    }
    case RECEIVE_FACEBOOK_DATA: {
      return {
        ...state,
        facebookData: action.payload
      };
    }
    case RECEIVE_FACEBOOK_IDENTITY: {
      return {
        ...state,
        identity: action.payload
      };
    }

    default:
      return state;
  }
};

export default facebookReducer;
