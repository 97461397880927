import {combineReducers} from 'redux';
import appReducer from './app/reducer';
import usersReducer from './users/reducer';
import authReducer from './auth/reducer';
import analyticsReducer from './analytics/reducer';
import facebookReducer from './facebook/reducer';
import refreshDataReducer from './refreshData/reducer';
import accountManagerReducer from './accountManager/reducer';
import adAccountsReducer from './adAccounts/reducer';
import pixelsReducer from './pixel/reducer';

export default combineReducers({
  app: appReducer,
  auth: authReducer,
  analytics: analyticsReducer,
  facebook: facebookReducer,
  users: usersReducer,
  refresh: refreshDataReducer,
  adAccounts: adAccountsReducer,
  accountManager: accountManagerReducer,
  pixels: pixelsReducer,
});
