export const ADD_AUTH_ERRORS = 'ADD_AUTH_ERRORS';
export const REMOVE_AUTH_ERRORS = 'REMOVE_AUTH_ERRORS';
export const SET_FETCH_AUTH_STATUS = 'SET_FETCH_AUTH_STATUS';
export const RECEIVE_AUTH_DATA = 'RECEIVE_AUTH_DATA';
export const RECEIVE_FACEBOOK_ACCOUNT_DATA = 'RECEIVE_FACEBOOK_ACCOUNT_DATA';
export const RECEIVE_OUTBRAIN_ACCOUNT_DATA = 'RECEIVE_OUTBRAIN_ACCOUNT_DATA';
export const SET_ADD_FACEBOOK_ACCOUNT_STATUS =
  'SET_ADD_FACEBOOK_ACCOUNT_STATUS';
export const RECEIVE_GOOGLE_ACCOUNT_DATA = 'RECEIVE_GOOGLE_ACCOUNT_DATA';
export const SET_ADD_GOOGLE_ACCOUNT_STATUS = 'SET_ADD_GOOGLE_ACCOUNT_STATUS';
export const SET_ADD_OUTBRAIN_ACCOUNT_STATUS = 'SET_ADD_OUTBRAIN_ACCOUNT_STATUS';
export const SET_REAUTHORIZE_FACEBOOK_STATUS = 'SET_REAUTHORIZE_FACEBOOK_STATUS';
export const SET_REAUTHORIZE_GOOGLE_STATUS = 'SET_REAUTHORIZE_GOOGLE_STATUS';
export const SET_DELETE_ACCOUNT_STATUS = 'SET_DELETE_ACCOUNT_STATUS';
