import {createGlobalStyle} from 'styled-components';
import {normalize} from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${normalize}

  * {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    margin: 0 auto;
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: 'Poppins', 'Work Sans', sans-serif;
    line-height: 1.8;
    color: #333333;
  }

  input,
  textarea,
  button {
    -webkit-appearance: none;
    font-family: 'Roboto', sans-serif;
    color: #333333;
  }
  label {
    font-size: 1.8rem;
    font-family: 'Poppins', 'Work Sans', sans-serif;
    color: #333333;
  }
  

  img {
    height: auto;
    width: 100%;
  }
`;

export default GlobalStyle;
