export const FetchStatus = {
  SUCCESS: 'success',
  FAILURE: 'failure',
  PENDING: 'pending'
};

export const UserRoles = {
  ADMIN: 'admin',
  MEDIA_BUYER: 'media_buyer'
};

export const DataSourcesNames = {
  FACEBOOK: 'Facebook',
  GOOGLE: 'Google',
  OUTBRAIN: 'Outbrain',
  CROSSROADS: 'Crossroads',
  AMG: 'AMG',
  PROPER: 'Proper',
  SYSTEM1: 'System 1'
}

export const DataSources = {
  trafficSources: {
    FACEBOK: {
      name: DataSourcesNames.FACEBOOK,
      dataIndex: 'facebook',
      disabled: false,
      image: {
        src: '/images/facebook.png'
      },
    },
    GOOGLE: {
      name: DataSourcesNames.GOOGLE,
      dataIndex: 'google',
      disabled: true,
      image: {
        src: '/images/google.png'
      },
    },
    OUTBRAIN: {
      name: DataSourcesNames.OUTBRAIN,
      dataIndex: 'outbrain',
      disabled: true,
      image: {
        src: '/images/outbrain.png'
      },
    },
  },
  networks: {
    CROSSROADS: {
      name: DataSourcesNames.CROSSROADS,
      dataIndex: 'crossroads',
      disabled: false,
      image: {
        src: '/images/crossroads.png'
      },
    },
    AMG: {
      name: DataSourcesNames.AMG,
      dataIndex: 'amg',
      disabled: true,
      image: {
        src: '/images/amg.png'
      },
    },
    PROPER: {
      name: DataSourcesNames.PROPER,
      dataIndex: 'proper',
      disabled: true,
      image: {
        src: '/images/sovrn.png'
      },
    },
    SYSTEM1: {
      name: DataSourcesNames.SYSTEM1,
      dataIndex: 'system1',
      disabled: true,
      image: {
        src: '/images/system1.png'
      },
    }
  }
}

export const DataSourcesArr = {
  trafficSources: [
    {
      name: DataSourcesNames.FACEBOOK,
      dataIndex: 'facebook',
      disabled: false,
      image: {
        src: '/images/facebook.png'
      },
    },
    {
      name: DataSourcesNames.GOOGLE,
      dataIndex: 'google',
      disabled: true,
      image: {
        src: '/images/google.png'
      },
    },
    {
      name: DataSourcesNames.OUTBRAIN,
      dataIndex: 'outbrain',
      disabled: true,
      image: {
        src: '/images/outbrain.png'
      },
    }
  ],
  networks: [
    {
      name: DataSourcesNames.CROSSROADS,
      dataIndex: 'crossroads',
      disabled: false,
      image: {
        src: '/images/crossroads.png'
      },
    },
    {
      name: DataSourcesNames.AMG,
      dataIndex: 'amg',
      disabled: true,
      image: {
        src: '/images/amg.png'
      },
    },
    {
      name: DataSourcesNames.PROPER,
      dataIndex: 'proper',
      disabled: true,
      image: {
        src: '/images/sovrn.png'
      },
    },
    {
      name: DataSourcesNames.SYSTEM1,
      dataIndex: 'system1',
      disabled: true,
      image: {
        src: '/images/system1.png'
      },
    },
  ],
}

export const CampaignStatuses = {
  PAUSED: 'red',
  ACTIVE: 'green'
}
