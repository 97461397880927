import { SET_REFRESH_DATA_STATUS } from './constants';

const initialState = {
  errors: [],
  refreshDataStatus: null,
};

const refreshDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_REFRESH_DATA_STATUS: {
      return {
        ...state,
        refreshDataStatus: action.payload
      };
    }

    default:
      return state;
  }
};

export default refreshDataReducer;
