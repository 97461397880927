import { notification as Notification } from 'antd';
import {useCallback} from 'react';
import {useRedux} from '../../lib/hooks';

const Toaster = () => {
  const [notification] = useRedux(['app.notification']);

  useCallback(() => {
    if(notification && notification.message) Notification[notification.type]({
      message: notification.message,
    });
  }, [notification])()

  return null;
}

export default Toaster
