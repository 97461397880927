import {
  ADD_USERS_ERRORS,
  REMOVE_USERS_ERRORS,
  SET_FETCH_USERS_FOR_MENU_STATUS,
  RECEIVE_USERS_FOR_MENU_DATA,
  SET_FETCH_USER_ACCOUNTS_STATUS,
  RECEIVE_USER_ACCOUNTS_DATA
} from './constants';

const initialState = {
  errors: [],
  fetchUsersForMenuStatus: null,
  fetchUserAccountsStatus: null,
  userAccountsData: [],
  usersDataForMenu: []
};

const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_USERS_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_USERS_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_USERS_FOR_MENU_STATUS: {
      return {
        ...state,
        fetchUsersForMenuStatus: action.payload
      };
    }
    case RECEIVE_USERS_FOR_MENU_DATA: {
      return {
        ...state,
        usersDataForMenu: action.payload
      };
    }
    case SET_FETCH_USER_ACCOUNTS_STATUS: {
      return {
        ...state,
        fetchUserAccountsStatus: action.payload
      };
    }
    case RECEIVE_USER_ACCOUNTS_DATA: {
      return {
        ...state,
        userAccountsData: action.payload
      };
    }

    default:
      return state;
  }
};

export default usersReducer;
