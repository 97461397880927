import {
  ADD_NETWORK_ERRORS,
  REMOVE_NETWORK_ERRORS,
  SET_FETCH_NETWORK_STATUS,
  RECEIVE_CAMPAIGNS_DATA,
  RECEIVE_CAMPAIGNS_COUNT,
} from './constants';

const initialState = {
  errors: [],
  fetchNetworkStatus: null,
  campaignsData: [],
  campaignsCount: 0,
};

const accountManagerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NETWORK_ERRORS: {
      return {
        ...state,
        errors: [...state.errors, action.payload]
      };
    }
    case REMOVE_NETWORK_ERRORS: {
      return {...state, errors: []};
    }
    case SET_FETCH_NETWORK_STATUS: {
      return {
        ...state,
        fetchNetworkStatus: action.payload
      };
    }
    case RECEIVE_CAMPAIGNS_DATA: {
      return {
        ...state,
        campaignsData: action.payload
      };
    }
    case RECEIVE_CAMPAIGNS_COUNT: {
      return {
        ...state,
        campaignsCount: action.payload
      };
    }

    default:
      return state;
  }
};

export default accountManagerReducer;
