import styled from 'styled-components';
import Spin from 'antd/lib/spin/index';
import PropTypes from 'prop-types';

const Loader = ({
  height = '60vh'
}) => {
  return (
    <Container height={height}>
      <Spin size="large" />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height};
`;

Loader.propTypes = {
  height: PropTypes.string
};

export default Loader;
